import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition02,
  CRestaurantJumbotron,
  LContact02,
  LContact03,
  LStores,
  CAnchorNav,
  CSimpleSlider02,
} from '../../../../components/_index';
// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/iki/iki_logo.svg',
            alt: '',
            widthSp: '160px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/iki/gifts/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/iki/gifts/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-iki/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <section className="u_mbLarge">
        <LWrap>
          <div className="u_pt100 u_mbLarge">
            <CSectTitle
              exClass="u_mb20"
              title={{
                en: 'GIFT SELECTION',
                ja: '',
              }}
            />
            <p className="u_fs10 u_tac u_mbMedium">
              ※商品のご希望数によってはご手配が整わない場合がございます。
              <br />
              あらかじめご了承ください。
            </p>
            <CAnchorNav
              exClass="u_mb10"
              data={[
                {
                  title: <>フィナンシェ</>,
                  link: {
                    href: '#financier',
                  },
                },
                {
                  title: <>クッキー</>,
                  link: {
                    href: '#cookie',
                  },
                },
                {
                  title: <>スティックケーキ</>,
                  link: {
                    href: '#stickcake',
                  },
                },
                {
                  title: <>パウンドケーキ</>,
                  link: {
                    href: '#poundcake',
                  },
                },
              ]}
            />
            <CAnchorNav
              exClass="u_mb0"
              data={[
                {
                  title: <>ベーコン・ソーセージ</>,
                  link: {
                    href: '#bacon_sausage',
                  },
                },
                {
                  title: <>紅茶</>,
                  link: {
                    href: '#tea',
                  },
                },
                {
                  title: <>ギフト券</>,
                  link: {
                    href: '#gift',
                  },
                },
              ]}
            />
            <div className="u_pt100" id="financier">
              <CSimpleSlider02
                width="736px"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts23.png',
                      alt: '',
                      caption: 'フィナンシェ',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts20.png',
                      alt: '',
                      caption: 'フィナンシェ 18個入',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts21.png',
                      alt: '',
                      caption: 'フィナンシェ 10個入',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts22.png',
                      alt: '',
                      caption: 'フィナンシェ 5個入',
                    },
                  },
                ]}
              />
              <div className="u_mb20">
                <h3 className="u_fwb u_mb10">フィナンシェ</h3>
                <p>お手土産やギフトにぴったりの、しっとりと焼き上げた優しい甘みのプレーンとチョコ。</p>
              </div>
              <CInlineDefinition02
                exClass="u_mb0"
                col={2}
                data={[
                  {
                    title: 'フィナンシェ 18個入',
                    titlesub: (
                      <>
                        プレーン12個、チョコ6個</>
                    ),
                    text: <>4,860円</>,
                  },
                  {
                    title: 'フィナンシェ 10個入',
                    titlesub: (
                      <>
                        プレーン5個、チョコ5個
                      </>
                    ),
                    text: <>3,024円</>,
                  },
                  {
                    title: 'フィナンシェ 5個入',
                    titlesub: (
                      <>プレーン3個、チョコ2個</>
                    ),
                    text: <>1,620円</>,
                  },
                ]}
              />
              <ul className="c_noteList u_mt20">
                <li>賞味期限:製造日より50日</li>
              </ul>
            </div>

            <div className="u_pt100" id="cookie">
              <CSimpleSlider02
                width="736px"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts.png',
                      alt: '',
                      caption: 'バケツ缶 Cookie 50枚',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts02.png',
                      alt: '',
                      caption: 'クッキーアソート 6個入',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts03.png',
                      alt: '',
                      caption: 'クッキーアソート 3個入',
                    },
                  },
                ]}
              />
              <div className="u_mb20">
                <h3 className="u_fwb u_mb10">クッキー詰合せ</h3>
                <p>甘さ控えめで軽い食感のクッキーです。</p>
              </div>
              <CInlineDefinition02
                exClass="u_mb0"
                col={2}
                data={[
                  {
                    title: 'バケツ缶 Cookie 50枚',
                    titlesub: (
                      <>
                        8種各6枚:レーズンサブレ／ショコラ／チーズ／チーペット／
                        <br />
                        チョコチップ／ディアマン／ココアナッツ／オレンジ
                        <br />
                        プリントクッキー（2枚）
                      </>
                    ),
                    text: <>3,240円</>,
                  },
                  {
                    title: 'クッキーアソート 6個入',
                    titlesub: (
                      <>
                        6種:オレンジ/プリントクッキー/ショコラ/チーペット/ディアマン/花型イチゴ
                      </>
                    ),
                    text: <>3,780円</>,
                  },
                  {
                    title: 'クッキーアソート 3個入',
                    titlesub: (
                      <>3種:チョコチップ／プリントクッキー／チーペット</>
                    ),
                    text: <>2,484円</>,
                  },
                ]}
              />
              <ul className="c_noteList u_mt20">
                <li>賞味期限:製造日より120日</li>
              </ul>
            </div>

            <div className="u_pt100" id="stickcake">
              <CSimpleSlider02
                width="736px"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts08.png',
                      alt: '',
                      caption: 'スティックパウンドケーキ4種セット',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts09.png',
                      alt: '',
                      caption: 'スティックパウンドケーキ・バウムクーヘンセット',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts10.png',
                      alt: '',
                      caption: 'バウムクーヘン',
                    },
                  },
                ]}
              />
              <div className="u_mb20">
                <h3 className="u_fwb u_mb10">
                  スティックケーキ&バウムクーヘン
                </h3>
                <p>
                  多彩な味わいを楽しめるスティックケーキと、卵をふんだんに使いしっとり焼き上げたバウムクーヘン。
                </p>
              </div>
              <CInlineDefinition02
                exClass="u_mb0"
                col={2}
                data={[
                  {
                    title: 'スティックパウンドケーキ4種セット',
                    titlesub: (
                      <>
                        チョコレート×2個／抹茶×2個／フルーツ×2個／オレンジ×2個
                      </>
                    ),
                    text: <>2,700円</>,
                  },
                  {
                    title: 'スティックパウンドケーキ・バウムクーヘンセット',
                    titlesub: (
                      <>
                        <ul className="c_circleList">
                          <li>
                            スティックパウンドケーキ:チョコレート×1個／抹茶×1個
                            <br />
                            ／フルーツ×1個／オレンジ×1個
                          </li>
                          <li>
                            バウムクーヘン:バニラ×1個／抹茶×1個／パンプキン×1個
                            <br />
                            ／チョコレート×1個／クリームチーズ×1個
                          </li>
                        </ul>
                      </>
                    ),
                    text: <>3,024円</>,
                  },
                  {
                    title: 'バウムクーヘン',
                    text: <>1,728円</>,
                    content: (
                      <>
                        <ul className="c_noteList">
                          <li>要冷蔵（6月～8月のみ）</li>
                        </ul>
                      </>
                    ),
                  },
                ]}
              />
              <ul className="c_noteList u_mt20">
                <li>賞味期限:製造日より40日</li>
              </ul>
            </div>

            <div className="u_pt100" id="poundcake">
              <CSimpleSlider02
                width="736px"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts11.png',
                      alt: '',
                      caption: 'オレンジケーキ',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts12.png',
                      alt: '',
                      caption: 'チョコレートパウンドケーキ',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts13.png',
                      alt: '',
                      caption: 'フルーツパウンドケーキS',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts14.png',
                      alt: '',
                      caption: 'パウンドケーキ詰合せ 3種',
                    },
                  },
                ]}
              />
              <div className="u_mb20">
                <h3 className="u_fwb u_mb10">パウンドケーキ</h3>
                <p>
                  甘く優しい口当たりのケーキは、お手土産にもぴったりな自慢の逸品です。
                </p>
              </div>
              <CInlineDefinition02
                exClass="u_mb0"
                col={2}
                data={[
                  {
                    title: 'オレンジケーキ',
                    text: <>2,160円</>,
                  },
                  {
                    title: 'チョコレートパウンドケーキ',
                    text: <>2,700円</>,
                  },
                  {
                    title: 'フルーツパウンドケーキL',
                    text: <>4,320円</>,
                    content: (
                      <>
                        <ul className="c_noteList">
                          <li>ご購入の4日前までに要予約</li>
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: 'フルーツパウンドケーキS',
                    text: <>3,024円</>,
                  },
                  {
                    title: 'パウンドケーキ詰合せ 3種',
                    titlesub: <>フルーツ／オレンジ／チョコレート</>,
                    text: <>5,400円</>,
                    content: (
                      <>
                        <ul className="c_noteList">
                          <li>ご購入の14日前までに要予約</li>
                        </ul>
                      </>
                    ),
                  },
                ]}
              />
              <ul className="c_noteList u_mt20">
                <li>要冷蔵</li>
                <li>賞味期限:製造日より14日</li>
              </ul>
            </div>

            <div className="u_pt100" id="bacon_sausage">
              <CSimpleSlider02
                width="736px"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts15.png',
                      alt: '',
                      caption: 'ベーコン',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts16.png',
                      alt: '',
                      caption: 'ベーコンとソーセージの詰合せ',
                    },
                  },
                ]}
              />
              <div className="u_mb20">
                <h3 className="u_fwb u_mb10">ベーコン･ソーセージ</h3>
                <p>
                  スパイス液に付け込んでから燻製･加熱をし、手間暇をかけたベーコンやソーセージをご用意いたしました。
                </p>
              </div>
              <CInlineDefinition02
                exClass="u_mb0"
                col={2}
                data={[
                  {
                    title: 'ベーコン',
                    text: <>2,160円</>,
                    content: (
                      <>
                        <ul className="c_noteList">
                          <li>要冷蔵</li>
                          <li>賞味期限:製造日より50日</li>
                          <li>ご購入の3日前までに要予約</li>
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: 'ベーコンとソーセージの詰合せ',
                    titlesub: (
                      <>
                        ベーコン×1個／フランクフルトソーセージ×1個
                        <br />
                        ／ペッパーフランクフルトソーセージ×1個
                      </>
                    ),
                    text: <>4,320円</>,
                    content: (
                      <>
                        <ul className="c_noteList">
                          <li>要冷蔵</li>
                          <li>賞味期限:製造日より25日</li>
                          <li>ご購入の3日前までに要予約</li>
                        </ul>
                      </>
                    ),
                  },
                ]}
              />
            </div>

            <div className="u_pt100" id="tea">
              <CSimpleSlider02
                width="736px"
                data={[
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts17.png',
                      alt: '',
                      caption: '紅茶単品（箱サイズ 11.2×11.2×3.9cm）',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/restaurants/iki/gifts/img_iki_gifts18.png',
                      alt: '',
                      caption: '紅茶2個詰合せ（箱サイズ 12.8×24.4×4.0cm）',
                    },
                  },
                ]}
              />
              <div className="u_mb20">
                <h3 className="u_fwb u_mb10">紅茶</h3>
                <p>
                  選りすぐりの紅茶を6種類お好みの組み合わせで2個入り、3個入りのセットもお選びいただけます。
                </p>
              </div>
              <CInlineDefinition02
                exClass="u_mb0"
                col={2}
                data={[
                  {
                    title: '紅茶単品（箱サイズ 11.2×11.2×3.9cm）',
                    text: <></>,
                    content: (
                      <>
                        <dl>
                          <dt>ダージリン（80g）</dt>
                          <dd className="text">1,620円</dd>
                        </dl>
                        <dl>
                          <dt>ダージリンホワイテ（20g）</dt>
                          <dd className="text">1,080円</dd>
                        </dl>
                        <dl>
                          <dt>ニルギリ（70g）</dt>
                          <dd className="text">864円</dd>
                        </dl>
                        <dl>
                          <dt>ヌワラエリア（70g）</dt>
                          <dd className="text">864円</dd>
                        </dl>
                        <dl>
                          <dt>アールグレイ（60g）</dt>
                          <dd className="text">864円</dd>
                        </dl>
                        <dl>
                          <dt>ウヴァ（70g）</dt>
                          <dd className="text">756円</dd>
                        </dl>
                      </>
                    ),
                  },
                  {
                    title: '紅茶2個詰合せ（箱サイズ 12.8×24.4×4.0cm）',
                    text: <></>,
                    content: (
                      <>
                        <ul className="c_noteList">
                          <li>セットにより料金は異なります。</li>
                        </ul>
                      </>
                    ),
                  },
                  {
                    title: '紅茶3個詰合せ（箱サイズ 12.8×36.0×4.0cm）',
                    text: <></>,
                    content: (
                      <>
                        <ul className="c_noteList">
                          <li>セットにより料金は異なります。</li>
                        </ul>
                      </>
                    ),
                  },
                ]}
              />
              <ul className="c_noteList u_mt20">
                <li>賞味期限:製造日より2年</li>
              </ul>
              <ul className="c_noteList u_mt40">
                <li>料金には消費税（8%）が含まれます。</li>
                <li>売り切れの際はご容赦ください。</li>
                <li>掲載の写真は実際と異なる場合がございます。</li>
              </ul>
            </div>
          </div>
        </LWrap>
      </section>
      <section className="u_bgGray">
        <LWrap>
          <div className="u_pt100 u_pb100" id="gift">
            <CSimpleSlider02
              width="736px"
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/iki/gifts/img_iki_gifts19.png',
                    alt: '',
                  },
                },
              ]}
            />
            <div className="u_mb20">
              <h3 className="u_fwb u_mb10">
                ROYAL PARK HOTELS GIFT CERTIFICATE
                <br />
                ロイヤルパークホテルズ ギフト券
              </h3>
              <p>
                ロイヤルパークホテルズでご宿泊･ご飲食などにご利用いただけます。
              </p>
            </div>
            <CInlineDefinition02
              exClass="u_mb0"
              col={2}
              data={[
                {
                  title: 'ギフト券',
                  text: <>1,000円</>,
                },
                {
                  title: 'ギフト券',
                  text: <>5,000円</>,
                },
                {
                  title: 'ギフト券',
                  text: <>10,000円</>,
                },
              ]}
            />
            <ul className="c_noteList u_mt10">
              <li>郵送をご希望の場合は書留でお送りします。（送料別途）</li>
              <li>ザ クラブ･ロイヤルパークホテルズは特典・割引対象外です。</li>
            </ul>
            <p className="u_mt40 u_fs12">＜ご利用に対しての注意事項＞</p>
            <ul className="c_circleList u_fs12">
              <li>
                ギフト券はロイヤルパークホテルズ各店にてお支払いの際にご利用いただけます。（一部店舗を除く）勝手ながらお釣りはお出しできませんのでご了承ください。
              </li>
              <li>
                ギフト券の払い戻し、現金化ならびに他額面券との交換はいたしかねますのでご了承ください。
              </li>
              <li>
                ギフト券の盗難、紛失などにつきましては当方は責任を負いかねますのでご了承ください。
              </li>
            </ul>
          </div>
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-iki/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
